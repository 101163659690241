<template>
<div class="homebody">
    <ul class="left">
        <li  v-has="'/home/outpatients'">
             <router-link to="/home/outpatients" active-class='ative'>新开就诊</router-link>
        </li>
        <li :class="isact?'ative':''" v-has="'/home/sickperson'">  <router-link to="/home/sickperson" active-class='ative'>患者列表</router-link></li>
        <div class="list">
            <h2>今日就诊</h2>
            <ul>
                <li v-for='item in list' :key='item.id' @dblclick="jump(item.cass_id)">
                    <div>{{item.num}}</div>
                    <div>{{item.name}}</div>
                    <div>{{item.time}}</div>
                </li>
                
                <div v-if='list.length==0' style="text-align:center;margin-top:20px">空</div>
                <div style="margin-top:10px;display: flex;justify-content: center;" v-else>
                    <el-pagination
                    small
                    layout="prev, pager, next"
                    :total="jz.total"
                    @current-change="jzhandleCurrentChange"
                    :current-page="jz.page"
                    >
                    </el-pagination>
                </div> 
            </ul>
        </div>
        <div class="list">
            <h2>挂号信息</h2>
            <ul>
                <li v-for='item in ghList' :key='item.id' @dblclick="ghinfo(item)">
                    <!--  @click="jump(item.cass_id)" -->
                    <div>{{item.no}}</div>
                    <div>{{item.name}}</div>
                    <div :class="item.cli_status=='未就诊'?'error':'success'">{{item.cli_status}}</div>
                </li>
                <div v-if='ghList.length==0' style="text-align:center;margin-top:20px">空</div>
                <div style="margin-top:10px;display: flex;justify-content: center;" v-else>
                    <el-pagination
                    small
                    layout="prev, pager, next"
                    @current-change="ghhandleCurrentChange"
                    :total="gh.total">
                    </el-pagination>
                </div> 
            </ul>
        </div>
        <el-dialog :title="item.name+' 挂号信息'" :visible.sync="itemshow">
            <div style="margin-bottom:30px;border-bottom:1px solid #ccc;padding-bottom:20px">
                <h2 style="font-weight: bold;font-size: 18px;">基础信息</h2>
                <div class="info">
                    <div>姓名：{{ item.name }}</div>
                    <div>性别：{{ item.sex }}</div>
                    <div>年龄：{{ item.age }}</div>
                    <div>身份证号：{{ item.idcard }}</div>
                    <div>手机号：{{ item.phone }}</div>
                </div>
            </div>
            <div style="margin-top:10px">
                <h2 style="font-weight: bold;font-size: 18px;">就诊信息</h2>
                <div class="info">
                    <div>挂号编号：{{ item.h}}</div>
                    <div>挂号时间：{{ item.reg_time }}</div>
                    <div>挂号类别：{{ item.reg_category }}</div>
                    <div>金额：{{ item.je }}</div>
                    <div>挂号性质：{{ item.reg_nature }}</div>
                    <div>就诊状态：{{ item.cli_status }}</div>
                    <div>诊断：{{ item.zd ? item.zd:"暂无" }}</div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="itemshow = false">取 消</el-button>
                <el-button type="primary" @click="jump2" :disabled="item.cli_status!='未就诊'">新开就诊</el-button>
            </div>
        </el-dialog>
    </ul>
    <div class="center" :style="{'width':!isact?'68%':'85%'}">
        <router-view/>
    </div>
    <div class="right" v-if='!isact'>
        <Seedoctor></Seedoctor>
    </div>

</div>
  
</template>

<script>

import {DayList,GhList} from "../../api/daylist/daylist"


export default {
    name:"Home",
    components:{
        Seedoctor:() => import('../../components/Seedoctor.vue')
    },
    data(){
        return {
         isact:false,
         list:[],
         jz:{
            page:1,
            total:0
         },
         ghList:[],
         gh:{
            page:1,
            total:0
         },
         itemshow:false,
         item:{},
        }
    },
    watch:{
        '$route'(a,b){
            // console.log(a)
            if(a.path=='/home/sickpersonitem'||a.path=='/home/casedetails'|| this.$route.path=='/home/addpatient'){
                this.isact=true
            }else{
                this.isact=false
            }
        }
    },
  async  mounted(){
       if(this.$route.path=='/home/sickpersonitem'||this.$route.path=='/home/casedetails'|| this.$route.path=='/home/addpatient'){
        this.isact=true
       }else{
        this.isact=false
       }
      this.DayList()
      this.GhList()

    },
    methods:{
        // handleSizeChange(val) {
        //     this.limit=val
        //     console.log(`每页 ${val} 条`);
        // },
        jzhandleCurrentChange(val) {
            this.jz.page=val
            this.DayList()
            // console.log(`当前页: ${val}`);
        },
        ghhandleCurrentChange(val) {
            this.gh.page=val
            this.GhList()
            // console.log(`当前页: ${val}`);
        },
        async GhList(){
         let res=await GhList({page:this.gh.page})
         if(res.code!=200) return this.error(res.msg)
            this.ghList=res.data.list
            this.gh.total=res.data.count

        //  console.log(res.data.list)
     }, 
     async DayList(){
         let res=await DayList({page:this.jz.page})
         if(res.code!=200) return this.error(res.msg)
            this.list=res.data.list
            this.jz.total=res.data.count

        //  console.log(res.data.list)
     }, 
     jump(item){
         this.$router.push({name:'Outpatients',replace:true,query:{id:item}})
         console.log(item)
     },

     ghinfo(item){
        this.item=item
        this.itemshow=true
        //  this.$router.push({name:'Outpatients',replace:true,query:{name:item}})
         
     },
     jump2(){
    
        this.itemshow=false
        this.$router.push({name:'Outpatients',replace:true,query:{name:this.item.patient_id}})
        this.item={}
     },
    }
}
</script>


<style scoped lang="scss">
.homebody{
    min-width: 1200px;
    min-height: calc(100vh - 40px);
    display: flex;
}
.left{
    width: 15%;
    height: calc(100vh - 40px);
    background: rgb(215, 215, 215);
    overflow-y: auto;
    box-sizing: border-box;
    border-right: 1px solid rgb(215, 215, 215);
    user-select: none;
    .list{
        width:98%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        background: #fff;
        padding: 10px 0;
        user-select: none;
        
        ul{
            width: 99%;
            margin: 0 auto;
            li{
            display: flex;
            justify-content:space-around ;
            margin-top: 20px;
            width: 100%;
            cursor: pointer;
            align-items: center;
            user-select: none;
        }
        }
       
    }
}
.left>li{
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 3px solid #ccc;
    box-sizing: border-box;
}
.center{
    width: 60%;
     position: relative;
}
.ative{
    color: #409EFF;
    background: #fff;
    width: 100%;
    display: inline-block;
}
.right{
    width: 20%;
}
.error{
    color: red;
    font-weight: 600;
    font-size: 14px;
}
.success{
    color: #409EFF;
    font-weight: 600;
    font-size: 14px;
}
.info{
   display: flex;flex-wrap: wrap;
   align-content: space-between;
   &>div{
    min-width: 30%;
    margin-right: 3%;
    margin-top: 20px;
   }
}





</style>