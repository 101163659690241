import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Home from "../views/Home/Home.vue"


Vue.use(VueRouter)
// 除去当前路由跳转当前页面
const originalPush=VueRouter.prototype.push
VueRouter.prototype.push=function(location){
  return originalPush.call(this,location).catch(err=>err)
}
const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  // {
  //   path:"/",
  //   redirect:"home",
  // },
  {
    path: '/',
    name: 'Index',
    redirect:"login",
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    children:[
       {
        path: 'home',
        name: 'Home',
        component: Home,
        redirect:"/home/outpatients",
        meta: { name:"首页" },
        children:[{
          path: 'outpatients',
          name: 'Outpatients',
          component: () => import(/* webpackChunkName: "about" */ "../views/Home/Outpatients.vue") ,
        },{
          path: 'sickperson',
          name: 'Sickperson',
          component: () => import(/* webpackChunkName: "about" */ "../views/Home/Sickperson.vue") ,
        },{
          path: 'sickpersonitem',
          name: 'Sickpersonitem',
          component: () => import(/* webpackChunkName: "about" */ "../views/Home/Sickpersonitem.vue") ,
        },{
          path: 'casedetails',
          name: 'Casedetails',
          component: () => import(/* webpackChunkName: "about" */ "../views/Home/Casedetails.vue") ,
        },{
          path: 'addpatient',
          name: 'Addpatient',
          component: () => import(/* webpackChunkName: "about" */ "../views/Home/Addpatient.vue") ,
        }
        ],

      },
      {
        path: 'order',
        name: 'Order',
        component: () => import(/* webpackChunkName: "about" */ "../views/Order/Order.vue") ,
        redirect:"/order/shipped",
        meta:{name:"订单管理"},
        children:[{
          path: 'tobepaid',
          name: 'ToBePaid',
          component: () => import(/* webpackChunkName: "about" */ "../views/Order/ToBePaid.vue") ,
        },{
          path: 'shipped',
          name: 'Shipped',
          component: () => import(/* webpackChunkName: "about" */ "../views/Order/Shipped.vue") ,
        },{
          path: 'harvested',
          name: 'Harvested',
          component: () => import(/* webpackChunkName: "about" */ "../views/Order/Harvested.vue") ,
        },{
          path: 'allOrder',
          name: 'AllOrder',
          component: () => import(/* webpackChunkName: "about" */ "../views/Order/AllOrder.vue") ,
        },
        {
          path: 'pay',
          name: 'Pay',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Pay.vue") ,
        },
        
        
        ],

      },
      {
        path: 'mould',
        name: 'Mould',
        component: () => import(/* webpackChunkName: "about" */ "../views/Mould/Mould.vue") ,
        redirect:"/mould/case",
        meta:{name:"常用模板"},
        children:[{
          path: 'case',
          name: 'Case',
          component: () => import(/* webpackChunkName: "about" */ "../views/Mould/Case.vue") ,
        },{
          path: 'doctorsadvice',
          name: 'Doctorsadvice',
          component: () => import(/* webpackChunkName: "about" */ "../views/Mould/DoctorsAdvice.vue") ,
        },{
          path: 'recipel',
          name: 'Recipel',
          component: () => import(/* webpackChunkName: "about" */ "../views/Mould/Recipel.vue") ,
        }
        
        ],

      },
      {
        path: 'drugstore',
        name: 'Drugstore',
        component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Drugstore.vue") ,
        redirect:"/drugstore/information",
        meta:{name:"药房管理"},
        children:[{
          path: 'information',
          name: 'Information',
          meta:{name:"药品信息管理"},
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Information.vue") ,
        },{
          path: 'saveInformation',
          name: 'SaveInformation',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/SaveInformation.vue") ,
        },{
          path: 'trilateral',
          name: 'Trilateral',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Trilateral.vue") ,
        },{
          path: 'trilateralItem',
          name: 'TrilateralItem',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/TrilateralItem.vue") ,
        },{
          path: 'address',
          name: 'Address',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Address.vue") ,
        },{
          path: 'pay',
          name: 'Pay',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Pay.vue") ,
        },{
          path: 'importdrugs',
          name: 'ImportDrugs',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/ImportDrugs.vue") ,
        },]
      },
      
      {
        path: 'statistics',
        name: 'Mould',
        component: () => import(/* webpackChunkName: "about" */ "../views/Statistics/index.vue") ,
        redirect:"/statistics/log",
       
        meta:{name:"统计管理"},
        children:[{
          path: 'log',
          name: 'mzlog',
          component: () => import(/* webpackChunkName: "about" */ "../views/Statistics/Mzlog.vue") ,
        },
        {
          path: 'check',
          name: 'Checklog',
          component: () => import(/* webpackChunkName: "about" */ "../views/Statistics/Checklog.vue") ,
        },
        {
          path: 'cost',
          name: 'Costlog',
          component: () => import(/* webpackChunkName: "about" */ "../views/Statistics/Costlog.vue") ,
        },
        {
          path: 'drug',
          name: 'Druglog',
          component: () => import(/* webpackChunkName: "about" */ "../views/Statistics/Druglog.vue") ,
        },
        {
          path: 'register',
          name: 'Register',
          component: () => import(/* webpackChunkName: "about" */ "../views/Statistics/Register.vue") ,
        },
        
        
        ],

      },
      {
        path: 'clinic',
        name: 'Clinic',
        meta:{name:"诊所管理"},
        component: () => import(/* webpackChunkName: "about" */ "../views/Clinic/index.vue") ,
        redirect:"/clinic/account",
        children:[{
          path: 'account',
          name: 'Account',
          component: () => import(/* webpackChunkName: "about" */ "../views/Clinic/Account.vue") ,
        },{
          path: 'check',
          name: 'Check',
          component: () => import(/* webpackChunkName: "about" */ "../views/Clinic/Check.vue") ,
        },{
          path: 'users',
          name: 'Users',
          component: () => import(/* webpackChunkName: "about" */ "../views/Clinic/Users.vue") ,
        },{
          path: 'cost',
          name: 'Cost',
          component: () => import(/* webpackChunkName: "about" */ "../views/Clinic/Cost.vue") ,
        },{
          path: 'department',
          name: 'Department',
          component: () => import(/* webpackChunkName: "about" */ "../views/Clinic/Department.vue") ,
        },
        ],

      },
      {
        path: 'cost',
        name: 'Cost',
        meta:{name:"收费/发药"},
        component: () => import(/* webpackChunkName: "about" */ "../views/Cost/Cost.vue") ,
        redirect:"/cost/pendingorder",
        children:[
          {
            path: 'pendingorder',
            name: 'PendingOrder',
            component: () => import(/* webpackChunkName: "about" */ "../views/Cost/PendingOrder.vue")
          }
          ,{
          path: 'tobecharge',
          name: 'Tobecharge',
          component: () => import(/* webpackChunkName: "about" */ "../views/Cost/Tobecharge.vue") ,
        },{
          path: 'tobechargeItem',
          name: 'TobechargeItem',
          component: () => import(/* webpackChunkName: "about" */ "../views/Cost/TobechargeItem.vue") ,
        },{
          path: 'charged',
          name: 'Charged',
          component: () => import(/* webpackChunkName: "about" */ "../views/Cost/Charged.vue") ,
        },{
          path: 'refund',
          name: 'Refund',
          component: () => import(/* webpackChunkName: "about" */ "../views/Cost/Refund.vue") ,
        },{
          path: 'medicine',
          name: 'Medicine',
          component: () => import(/* webpackChunkName: "about" */ "../views/Cost/Medicine.vue") ,
        },
        {
          path: 'pay',
          name: 'Pay',
          component: () => import(/* webpackChunkName: "about" */ "../views/Drugstore/Pay.vue") ,
        },
        
        ],

      },
      {
        path: 'scalelist',
        name: 'ScaleList',
        component: () => import(/* webpackChunkName: "about" */ "../views/Home/Scalelist.vue") ,
      },
      {
        path: 'scaleItem',
        name: 'ScaleItem',
        component: () => import(/* webpackChunkName: "about" */ "../views/Home/ScaleItem.vue") ,
      },
      {
        path: 'scientificResearch',
        name: 'ScientificResearch',
        component: () => import(/* webpackChunkName: "about" */ "../views/ScientificResearch/ScientificResearch.vue") ,
      },
      {
        path: 'examineLog',
        name: 'ExamineLog',
        meta:{name:"审核记录"},
        redirect:"/examineLog/unaudited",
        component: () => import(/* webpackChunkName: "about" */ "../views/ExamineLog/index.vue") ,
        children:[
          // "/examineLog/","/examineLog/audited"
          {
            path: 'unaudited',
            name: 'Unaudited',
            meta:{name:"待审核"},
            component: () => import(/* webpackChunkName: "about" */ "../views/ExamineLog/Unaudited.vue") ,
          },
          {
            path: 'audited',
            name: 'Audited',
            meta:{name:"已审核"},
            component: () => import(/* webpackChunkName: "about" */ "../views/ExamineLog/Audited.vue") ,
          },
          {
            path: 'info',
            name: 'Info',
            meta:{name:"审核详情"},
            component: () => import(/* webpackChunkName: "about" */ "../views/ExamineLog/Info.vue") ,
          },
        ]
      },
    ],
  },
  // {
  //   path: '/introduce',
  //   name: 'Introduce',
  //   component: Introduce,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/Introduces.vue')
  // },
]

const router = new VueRouter({
  routes
})

export default router
