import axios from 'axios'
import QS from 'qs'
import {Loading} from 'element-ui'
import Router from './../router/index'
import store from '@/store'

//默认超时时间
axios.defaults.timeout =1000*60*60*3;
//post请求的Content-Type
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers['Authorization']= store.state.token
let loadingInstance;
const AXIOS_HTTP = axios.create({
  baseURL:'/api'
});

//请求过滤,显示加载中
AXIOS_HTTP.interceptors.request.use(
  config => {
    let otyp = Object.prototype.toString.call(config.data);
    //只有当data是from类型，并且有noloading字段时候不添加加载等待效果
    if (!(
        ((otyp === '[object FormData]') || (otyp === '[object String]')) && (config.data.indexOf('noloading') > 0))
    ) {
      loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
      });
    }
    if (!ctool.strIsEmpty(ctool.getToken())) {
      config.headers['Authorization'] = ctool.getToken();
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//收到数据后的过滤
AXIOS_HTTP.interceptors.response.use(
  response => {
   
    if (!ctool.strIsEmpty(loadingInstance)) loadingInstance.close();
  //    if(!ctool.strIsEmpty(response.data.datas.list) && ctool.isJson(response.data.datas.list)){
	// 	response.data.datas.list = JSON.parse(response.data.datas.list);
	// }
    if (response.data.code === 200) {
      return Promise.resolve(response);
    }  else if (response.data.code == 403) {
      ctool.msgError('授权过期，请重新登录');
      this.$router.push('/Login')     
      return false
     } else if (response.data.code == 401) {
      ctool.msgError('此账号没有授权');
      this.$router.push('/Login')
      return false
     } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (!ctool.strIsEmpty(loadingInstance)) loadingInstance.close();
    ctool.msgError('请检查网络');
    // return Promise.reject(error.response);
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    AXIOS_HTTP.get(url, {
      params: params,
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}

/*
 * 入参会被序列化，后台使用getParameter一个一个获取
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    AXIOS_HTTP.post(url, QS.stringify(params))
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

/*
 * 入参是js对象，后台直接获取整个对象
 */
export function postObj(url, params) {
  console.log('post_obj----->',params);
  return new Promise((resolve, reject) => {
    AXIOS_HTTP.post(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}
