import {post} from './http'
import {postObj} from "./http";
import {get} from "./http";

const apis = {
	login: p => post("/login", p),
	deleteLeechdom: p => post("/management/deleteLeechdom", p),



	get: function(url, parmas) {
		return get(url, parmas);
	},
	commPostObj: function(url, parmas) {
		return postObj(url, parmas);
	},
	commPost: function(url, parmas) {
		return post(url, parmas);
	},
	commPostNoLoading: function(url, params) {
		params.noloading = 'true';
		return post(url, params);
	},

}
export default apis

//post-json传参例：
// apis.login({}).then(data => {
// }).catch(err => {
// })
