import axios from 'axios'
import store from '@/store';
import { Notification, MessageBox, Message, Loading } from 'element-ui'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
let downloadLoadingInstance;
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL:'',
    // 超时
    timeout:1000*60*60*3
  })
  // request拦截器
service.interceptors.request.use(config => {
    return config
  }, error => {
      console.log(error)
      Promise.reject(error)
  })
  
  // 响应拦截器
  service.interceptors.response.use(res => {
     return res
    },
    error => {
      return Promise.reject(error)
    }
  )
  //封装post,get方法
const http = {
    get(url='',params={}){

      if(url=='/api/searchzd'||url=='/api/scaleshow'){

      }else{
       downloadLoadingInstance = Loading.service({ text: "正在加载...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
      }
       let headers={'Content-Type': 'application/json;charset=UTF-8'}
  
      if(store.state.token)  headers['Authorization'] = store.state.token
     
      return new Promise((resolve, reject) => {
        service({
          url,
          params,
          headers,
          method: 'GET'
        }).then(res => {

          resolve(res.data)
          
          downloadLoadingInstance.close();
          if(res.data.code==201){
            window.sessionStorage.clear()
            location.href = '/login'
          }
          if(res.data.code==500)  Message.error(res.data.msg)
          return res
        }).catch(error => {
          reject(error)
          Message.error(error)
          downloadLoadingInstance.close();
        })
      })
    },
    post(url='',params={},header={}){
      let headers={'Content-Type': 'application/json;charset=UTF-8',...header}
      // let headers={'Content-Type': 'multipart/form-data'}
      if(store.state.token)  headers['Authorization'] = store.state.token
      return new Promise((resolve, reject) => {
        
        if(url=='/api/savetmpcass'||url=='/api/c/lxgetstatus'){

        }else{
          downloadLoadingInstance = Loading.service({ text: "正在加载...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
        }
        service({
          url,
          data:params,
          headers,
          method: 'POST'
        }).then(res => {
          resolve(res.data)
          if(res.data.code==201){
            window.sessionStorage.clear()
            location.href = '/login'
          }
          if(res.data.code==500)  Message.error(res.data.msg)
          downloadLoadingInstance.close();
          return res
        }).catch(error => {
          reject(error)
          Message.error(error)
          downloadLoadingInstance.close();
        })
      })
    }
  }

  export default http