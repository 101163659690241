import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    token:false,
    userid:false,
    name:false,
    org_name:false,
    org_id:false,
    routers:[],
  },
 
  mutations: {
    setUser(state,data){
      state.token=data.token
      state.userid=data.userid
      state.name=data.name
      state.org_name=data.org_name
      state.org_id=data.org_id
      state.routers=data.routers
      console.log(state.routers)
    },
  },
  actions: {
    setUserApi(context,data){
      context.commit('setUser',data)
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage, // 或者 localStorage
  })]
})
