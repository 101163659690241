/**
 * @Author: Zhouc52
 * @Date: 2019/09/18 9:30
 * 工具类，公用方法写在这里
 */
import router from './../router/index'
import {Message,MessageBox} from 'element-ui'
// import FileSaver from 'file-saver'

const commTool = {
  strIsEmpty: function (str) {
    if (str == null || typeof str == undefined || str == "undefined" || str == '' || str == "" || str == 'null') {
      return true;
    }
    return false;
  },
  filterStr: function (str) {
    if (this.strIsEmpty(str)) return '';
    return str;
  },
  setValue: function (key, value) {
    sessionStorage.setItem(key, value);
  },
  getValue: function (key) {
    return sessionStorage.getItem(key);
  },
  setObj: function (key, obj) {
    obj = JSON.stringify(obj);
    sessionStorage.setItem(key, obj);
  },
  getObj: function (key) {
    if (this.strIsEmpty(sessionStorage.getItem(key))) {
      return null;
    }
    var obj = JSON.parse(sessionStorage.getItem(key));
    return obj;
  },
  removeItem: function (key) {
    sessionStorage.removeItem(key);
  },
  clearStore:function(){
    sessionStorage.clear();
  },
  setUinfo:function(obj){
    this.setObj("userInfo",obj);
  },
  setToken:function(token){
    this.setValue("token",token);
  },
  setMenus:function(obj){
    this.setObj("menus",obj);
  },
  getMenus:function(){
    return this.getObj("menus");
  },
  setOrgId:function(oid){
    this.setValue("oid",oid);
  },
  getOrgId:function(){
    return this.getValue("oid");
  },
  setOrgName:function(oName){
    this.setValue("oName",oName);
  },
  getOrgName:function(){
    return this.getValue("oName");
  },
  getToken:function(){
    return this.getValue("token");
  },
  getUid: function(){
    return this.getObj("userInfo").u_id;
  },
  getUname: function(){
    return this.getObj("userInfo").nick_name;
  },
  msg: function (msg) {
    Message( {
      message:msg
    });

  },
  msgSuccess: function (msg) {
    Message({
      message: msg,
      type: 'success'
    });
  },
  msgWarn: function (msg) {
    Message({
      message: msg,
      type: 'warning'
    });
  },
  msgError: function (msg) {
    Message({
      message: msg,
      type: 'error'
    });
  },
  msgConfirm: function (str) {
    return new Promise((resolve, reject) => {
      MessageBox.confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resolve()
      })
        .catch(() => {
          reject()
        })
    });
  },
  msgAlert: function(str){
    return new Promise((resolve, reject) => {
      MessageBox.alert(str, '提示', {
        confirmButtonText: '确定'
      }).then(() => {
        resolve()
      }).catch(() => {
        reject()
      })
    });
  },
  countTime: function (begindate, enddate) {
    var date3;
    var re = new RegExp("-", "g");
    begindate = begindate.replace(re, '/');
    if (!this.strIsEmpty(enddate)) {
      enddate = enddate.replace(re, '/');
      date3 = new Date(enddate).getTime() - new Date(begindate).getTime();
    } else {
      enddate = new Date();
      date3 = enddate.getTime() - new Date(begindate).getTime();
    }
    //计算出相差天数
    var days = Math.floor(date3 / (24 * 3600 * 1000))
    //计算出小时数
    var leave1 = date3 % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000))
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000)        //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000))
    /*  //计算相差秒数
     var leave3=leave2%(60*1000)      //计算分钟数后剩余的毫秒数
     var seconds=Math.round(leave3/1000)*/
    return days + "天" + hours + "小时" + minutes + "分";
  },
  Format: function (fmt, d) {
    var o = {
      "M+": d.getMonth() + 1, //月份
      "d+": d.getDate(), //日
      "h+": d.getHours(), //小时
      "m+": d.getMinutes(), //分
      "s+": d.getSeconds(), //秒
      "q+": Math.floor((d.getMonth() + 3) / 3), //季度
      "S": d.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  },
  formatDate: function (type, date1) {
    var re = new RegExp("-", "g");
    date1 = date1.replace(re, '/');
    var d = new Date(date1);
    switch (type) {
      case 1:
        return this.Format('yyyy-MM-dd', d);
      case 2:
        return this.Format('yyyy-MM-dd hh:mm:ss', d);
      case 3:
        return this.Format('yyyyMMdd', d);
      default:
        return d;
    }
  },
  //判断是否符合json格式
  isJson:function (str) {
    if (typeof str == 'string') {
      try {
        var obj=JSON.parse(str);
        if(typeof obj == 'object' && obj ){
          return true;
        }else{
          return false;
        }

      } catch(e) {
        console.log('error：'+str+'!!!'+e);
        return false;
      }
    }
  },
  //--表单校验手机号
  formTel:function(rule, value, callback){
    const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
    if (!value) {
      return callback(new Error('手机号不能为空'))
    }
    setTimeout(() => {
      // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
      // 所以我就在前面加了一个+实现隐式转换
      if (!Number.isInteger(+value)) {
        callback(new Error('请输入数字值'))
      } else {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('手机号格式不正确'))
        }
      }
    }, 100)
  },
  //--表单校验邮箱
  formEmail:function(rule, value, callback){
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    if (!value) {
      return callback(new Error('邮箱不能为空'))
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的邮箱格式'))
      }
    }, 100)
  },
  //--表单校验身份证号
  formCardNum:function(rule, code, callback){
    var city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 " };
    var tip = "";
    var pass = true;

    if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
      tip = "身份证号格式错误"
      pass = false;
    } else if (!city[code.substr(0, 2)]) {
      tip = "地址编码错误"
      pass = false
    } else {
      // 18位身份证需要验证最后一位校验位
      if (code.length === 18) {
        code = code.split('')
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        // 校验位
        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
        var sum = 0
        var ai = 0
        var wi = 0
        for (var i = 0; i < 17; i++) {
          ai = code[i]
          wi = factor[i]
          sum += ai * wi
        }
        var last = parity[sum % 11];
        if (parity[sum % 11] != code[17]) {
          tip = "校验位错误"
          pass = false
        }
      }
    }
    if (!pass) {
      callback(new Error(tip))
    } else {
      callback()
    }
  },
  //保存内容到文件并下载到本地的方法
  saveFile:function(name,data){
    var cstr=data;
    if(data instanceof Object){
      cstr=JSON.stringify(data);
    }
    var file = new File([cstr], name, {type: "text/plain;charset=utf-8"});
    // FileSaver.saveAs(file);
  }
}

export default commTool

// 使用例：ctool.setToken